<template>
  <page-template>
    <div class="page-content-wrapper py-2">
      
      <div class="card-section mt-2">
        <card @page-change="(_) => loadCards()" @card="selectCardType"></card>
        
        <Transition name="bounce">
          <transaction-pin></transaction-pin>
        </Transition>

        <Transition name="bounce">
          <div class="notification-area mt-4">
            <div class="container">
              <card-list :cards="cards.data || []" :topic="`${cardType} cards list`"></card-list>
            </div>
          </div>
        </Transition>
        <div class="my-6"></div>
      </div>
    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import TransactionPin from '@/components/common/wallet/TransactionPin.vue'
import ProductPackage from '@/components/common/guard/ProductPackage.vue'
import PageTemplate from '@/components/templates/PageTemplate.vue'
// import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import CardList from '@/components/pages/card/CardList.vue'
import Card from '@/components/pages/card/Card.vue'
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core';

export default {
  name: 'Dashboard',
  components: {
    PageTemplate,
    Card,
    CardList,
    TransactionPin,
    ProductPackage
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {}
  },
  data() {
    const { banner } = this.appConfig || {};
    return {
      cardType: 'virual',
      banner: banner || require("@/assets/images/common/22.jpeg"),
    }
  },
  computed: {
    ...mapGetters([
      "cards",
      "appConfig"
    ])
  },
  mounted() {
    this.loadCards()
  },
  methods: {
    ...mapActions([
      "getCards"
    ]),
    loadCards() {
      this.getCards({})
    },
    selectCardType(type) {
      this.cardType = type
      //fetch card type
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn.btn-warning {
  color: var(--primary-color) !important;
}
.cta-card {
  margin: 0px -16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  /* border-bottom-right-radius: 80px;
  border-bottom-left-radius: 80px; */
  overflow: hidden;
  margin-bottom: 9px;
}
.card-section {
  position: relative;
  margin-top: -63px;
  /* z-index: 1; */
}

.cta-btn {
  font-size: 10px;
}


</style>
