export const loadCards = (state, cards) => {
  state.cards = cards;
};


const CardMutation = {
  loadCards
};

export default CardMutation;
