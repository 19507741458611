<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay pb-4" :style="{'background-image': `url(${banner})`}">
        <div class="container">
          <div class="card-body px-4 pb-5">
            <h6 class="mb-3 text-white">Transaction Summary.</h6>

            <div class="btn-group" role="group" aria-label="Basic outlined example">
              <button class="btn btn-sm btn-outline-warning" style="border-right: 1px" @click="load24hrsTransactions()">last 24hr</button>
              <button class="btn btn-sm btn-outline-warning" @click="load1WeekTransactions()">1 week</button>
              <button class="btn btn-sm btn-outline-warning" @click="load30DaysTransactions()">30 days</button>
              <button class="btn btn-sm btn-outline-warning" 
                data-bs-toggle="modal" 
                data-bs-target="#filterTransactionModal"
              >
                <i class="pi pi-list-check"></i>
              </button>
            </div>

          </div>
        </div>
      </div>

      <div class="notification-area">    
        <div class="container">

          <div class="card mb-4 position-relative" style="z-index: 10;">
            <div class="card-body direction-rtl">
              <div class="search-form-wrapper">
                <p class="mb-2 fz-12">Search transaction record</p>
                <!-- Search Form -->
                <form class=" border-bottom" action="#">
                  <div class="input-group">
                    <input class="form-control form-control-clicked" type="search" v-model="search" :onkeyup="searchTransactions" placeholder="Search...">
                    <button class="btn btn-dark" type="submit"><i class="bi bi-search fz-14"></i></button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <transaction-list :transactions="transactionList" :loading="loading"></transaction-list>

          <div class="d-flex justify-content-center">
            <button class="btn m-1 btn-sm btn-warning shadow" :disabled="transactions().current_page === 1" @click="loadMoretransaction({...query, page: transactions().current_page-1})">
              Prev
            </button>
            <button class="btn m-1 btn-sm btn-warning shadow" :disabled="transactions().current_page === transactions().last_page" @click="loadMoretransaction({...query, page: transactions().current_page+1})">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>

    <modal title="Filter Transactions" id="filterTransactionModal">
      <!-- <div class="text-center">
        feature will be available soon
      </div> -->
      <form>
        <div class="form-group">
          <input class="form-control form-control-clicked" id="fund-amount" type="number" placeholder="Enter Name/Reference" v-model="query.search" >
        </div>
        <div class="form-group">
          <select 
            class="form-control form-control-clicked" 
            id="transaction-type" 
            placeholder="Airtime" v-model="query.category">
            <option value="" disabled selected>Select Transaction Type</option>
            <option v-for="(type, i) in transactionTypes" :key="i" :value="type">{{ snakeToTitleCase(type) }}</option>
          </select>
        </div>
        <div class="form-group">
          <select 
            class="form-control form-control-clicked" 
            id="transaction-status" 
            placeholder="Successful" v-model="query.status">
            <option value="" disabled selected>Select Transaction Status</option>
            <option v-for="(status, i) in statusList" :key="i" :value="status">{{ snakeToTitleCase(status) }}</option>
          </select>
        </div>
        
        <app-button 
          @click="applyFilter" 
          :classes="'btn m-1 btn-sm btn-warning shadow'" 
          :title="'Apply Filter'"
        ></app-button>

        <app-button 
          @click="applyFilter(true)" 
          :classes="'btn m-1 btn-sm btn-success shadow'" 
          :title="'Download'"
        ></app-button>
      </form>
    </modal>
  </page-template>
</template>

<script>
// @ is an alias to /src
import PageTemplate from '@/components/templates/PageTemplate.vue'
import TransactionList from '@/components/pages/transaction/TransactionList.vue'
import { mapActions, mapGetters } from 'vuex'
import AppButton from '@/components/common/form/AppButton.vue';
import { ACCOUNT_TYPES } from "../../../store/states/account.state"
import Modal from '@/components/common/modal/Modal.vue'
import moment from 'moment'
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import { snakeToTitleCase, transactionList as transactionTypes, statusList } from "@/utils"

export default {
  name: 'Transaction',
  components: {
    PageTemplate,
    TransactionList,
    Modal,
    AppButton
  },
  data() {
    const { banner } = this.appConfig() || {};
    return {
      query: {},
      banner: banner || require("@/assets/images/common/22.jpeg"),
      transactionList: [],
      timer: null,
      search: "",
      loading: false,
      snakeToTitleCase,
      transactionTypes,
      statusList
    }
  },
  mounted() {
    this.initQuery()
    this.getTransactions(this.query).then(() => {
      this.filterTransaction();
    })
  },
  methods: {
    ...mapActions([
      "getTransactions"
    ]),
    ...mapGetters([
      "transactions",
      "activeAccount",
      "appConfig"
    ]),
    initQuery() {
      let category = this.activeAccount();
      if ( category === ACCOUNT_TYPES.HOME ) {
        category = null
      } else if ( category === ACCOUNT_TYPES.INVESTMENT ) {
        category = ACCOUNT_TYPES.CRYPTO
      }
      
      this.query = {
        ...this.query,
        category
      }
    },
    loadMoretransaction(query) {
      // console.log(this.query, query)
      const self = this;
      this.loading = true
      this.getTransactions(query).then((response) => {
        self.filterTransaction();
        if ( query.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','transactions.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }
      }).finally(() => {
        self.query.get_csv = false
        self.loading = false
      })
    },
    load24hrsTransactions() {
      this.initQuery()
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'day').format("YYYY-MM-DD")
      // this.query = `${this.query}&from=${from}&to=${to}`
      this.query = {
        ...this.query,
        from,
        to
      }
      this.loadMoretransaction(this.query)
    },
    load1WeekTransactions() {
      this.initQuery()
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'week').format("YYYY-MM-DD")
      this.query = {
        ...this.query,
        from,
        to
      }
      this.loadMoretransaction(this.query)
    },
    load30DaysTransactions() {
      this.initQuery()
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'M').format("YYYY-MM-DD")
      // this.query = `${this.query}&from=${from}&to=${to}`
      this.query = {
        ...this.query,
        from,
        to
      }
      this.loadMoretransaction(this.query)
    },
    searchTransactions(e = null) {
      const value = e === null ? this.search : e.target.value;
      this.filterTransaction(value)
    },
    filterTransaction(search = null) {
      if (!this.transactions().data || this.transactions().data.length === 0) {
        this.transactionList = [];
        return;
      }

      const transactions = this.transactions().data;

      if (search === null) {
        this.transactionList = transactions;
        return;
      }

      this.transactionList = transactions.filter( transaction => (
        transaction.category.includes(search) ||
        transaction.description.includes(search) ||
        `${transaction.net_amount}`.includes(search) ||
        `${transaction.gross_amount}`.includes(search) ||
        transaction.reference.includes(search) ||
        transaction.status.includes(search) ||
        transaction.type.includes(search) 
      ));

      this.query = {
        search
      }
      if ( this.timer !== null ) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.loadMoretransaction(this.query)
      }, 2000)
      

    },
    applyFilter(get_csv = false) {
      // const to = moment(new Date).format("YYYY-MM-DD")
      // const from = moment(new Date).subtract(1, 'M').format("YYYY-MM-DD")
      this.query = {
        ...this.query,
        get_csv,
        // from,
        // to
      }
      this.loadMoretransaction(this.query)
      const filterModal = document.getElementById('filterTransactionModal');
      const _filterModal = bootstrapBundleMin.Modal.getInstance(filterModal)
      _filterModal.hide();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transaction.card, .accordion-item {
  background-color: transparent !important
}
.transaction.card {
  border: none;
}
.cta-card {
  margin: 0px -16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-bottom-right-radius: 80px;
  border-bottom-left-radius: 80px;
  overflow: hidden;
}
.notification-area {
  position: relative;
  margin-top: -63px;
  /* z-index: 10; */
}
</style>
