import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
export const snakeToCamelCase = (str) =>
  str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  );

export const snakeToTitleCase = (str) => {
  if(!str || typeof str !== "string") return "";
  return str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace("-", " ")
      .replace("_", " ")
  );
}

export const clickToCopy = (text) => {
  navigator.clipboard.writeText(text).then(function() {
    toaster.success('Copied to clipboard!');
  }, function(err) {
    toaster.show('Could not copy text: ', err);
  });
}