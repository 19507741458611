<template>
    <modal :title="title || 'Add Bank Account'" :id="id">
        <form>
            <div class="form-group mb-3">
                <label class="form-label" for="bank">Bank</label>
                <v-select :options="banks()" label="name" v-model="v$.bankAccount.bank.$model" @input="() => validateAccount(null)"></v-select>
                <!-- <select :disabled="loaders().enquire" v-model="v$.bankAccount.bank.$model" class="form-control" id="bank">
                    <option v-for="(c, i) in banks()" :key="i" :value="c">{{ c.name }}</option>
                </select> -->
            </div>

            <div class="form-group mb-3">
                <label class="form-label" for="account-number">Account Number</label>
                <input class="form-control" :disabled="loaders().enquire" id="account-number" v-model="v$.bankAccount.account_number.$model" type="tel" pattern="[0-9]*" maxlength="10" placeholder="00012..." @keyup="validateAccount">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.bankAccount.account_number.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label class="form-label" for="account-number">Account Name</label>
                <input class="form-control" id="account-number" v-model="v$.bankAccount.account_name.$model" type="text" disabled>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.bankAccount.account_name.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <app-button @click="createBankAccount" :title="'Add Bank Account'" :loading="loaders().addBankAccount || loaders().activateMandate" :disabled="loaders().addBankAccount || loaders().activateMandate || v$.bankAccount.$invalid" ></app-button>
        </form>
    </modal>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue'
    import useVuelidate from '@vuelidate/core';
    import { mapGetters, mapActions } from 'vuex';
    import { required, minLength, maxLength, numeric } from '@vuelidate/validators';
    import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
    import AppButton from '@/components/common/form/AppButton.vue';

    export default {
        name: 'ModalAddBankAccount',
        props: ['title', 'id', 'bank_type', 'is_default', 'active_mandate'],
        components: {
            Modal,
            AppButton
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations() {
            return {
                bankAccount: {
                    account_number: { required, min: minLength(10), max: maxLength(11), numeric },
                    bank: { required },
                    account_name: { required }
                }
            }
        },
        data() {
            return {
                bankAccount: {},
            }
        },
        mounted() {
            this.getBanks({type: this.bank_type});
        },
        methods: {
            ...mapGetters([
                "loaders",
                "banks"
            ]),
            ...mapActions([
                "getBanks", 
                "enquire",
                "addBankAccount",
                "activateMandate"
            ]),
            createBankAccount(e) {
                e.preventDefault;
                const data = {
                    account_number: this.bankAccount.account_number, 
                    account_name : this.bankAccount.account_name, 
                    bank_code : this.bankAccount.bank.code, 
                    bank_name : this.bankAccount.bank.name,
                    is_default: this.is_default
                }
                
                const self = this;
                this.addBankAccount(data).then( () => {
                    if (self.active_mandate) {
                        self.activateMandate().then( res => {
                            const { redirect_url } = res;
                            window.location.href = redirect_url;
                        })
                    } else {
                        const thisModal = document.getElementById(self.id);
                        const _thisModal = bootstrapBundleMin.Modal.getInstance(thisModal)
                        _thisModal.hide();
                        self.bankAccount = {};
                    }
                });
            },
            validateAccount(e = null) {
                const account_number = e !== null ? e.target.value : this.bankAccount.account_number;
                if ( account_number.length !== 10 )
                    return false;
                // validate
                const data = {
                    bank_code: this.bankAccount.bank.code,
                    account_number
                }
                const self = this
                this.enquire(data).then( res => {
                    self.bankAccount.account_name = res.account_name
                }).catch()
            }
        }
    }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  