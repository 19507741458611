<template>
    <div>
        <div v-if="stage===stages.SELECT_TYPE">
          <div class="text-center"> 
            <h6>Addition Method</h6>
          </div>
          <div class="text-center mt-2">
            <p>Specify how you want to add this user!</p>
            <button class="btn m-1 btn-sm btn-warning shadow" @click="setStage('create-form')">
              Create New
            </button>
            <button class="btn m-1 btn-sm btn-warning shadow" @click="setStage('user-form')">
              Search User
            </button>
          </div>
        </div>
        <div class="create-user" v-else-if="stage===stages.CREATE_FORM"> 
            <h6>
                Register User
                <br>
                <small>We will send a sign up code to user phone</small>
            </h6>
            <div class="form-group">
                <label class="form-label" for="userNameInput">Name</label>
                <input class="form-control form-control-clicked" id="userNameInput" v-model="v$.user.name.$model" type="text" placeholder="Akande Lawal">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.name.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="userPhoneInput">Phone</label>
                <input class="form-control form-control-clicked" id="userPhoneInput" v-model="v$.user.phone.$model" type="text" inputmode="numeric" pattern="[-+]?[0-9]*[.,]?[0-9]+" placeholder="080123456789">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.phone.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="userEmailInput">Email</label>
                <input class="form-control form-control-clicked" id="userEmailInput" v-model="v$.user.email.$model"  type="text" placeholder="akande@email.com">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.email.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
                <div class="error-msg">{{ key }}: {{ error }}</div>
            </div>
            <div class="text-center my-1 mb-8">
                <app-button 
                @click="validateAndSendCode" 
                :title="'Continue'" 
                :loading="loaders().registerUserInit" 
                :disabled="loaders().registerUserInit || v$.user.$invalid" 
                ></app-button>
            </div>
            <hr/>
            <div @click="setStage(stages.SELECT_TYPE)">back</div>
        </div>
        <div class="otp-verify-form mt-4" v-else-if="stage===stages.OTP_FORM">
            <h6>
                OTP
                <br>
                <small>Enter the OTP Code sent to your phone</small> 
            </h6>
            <form>
                <div class="input-group mb-3 otp-input-group" id="otp">
                    <input class="form-control otp-input" type="text" maxlength="1" id="input1" v-on:keyup="{}">
                    <input class="form-control otp-input" type="text" maxlength="1" id="input2" v-on:keyup="{}">
                    <input class="form-control otp-input" type="text" maxlength="1" id="input3" v-on:keyup="{}">
                    <div class="pt-2">&nbsp;--&nbsp;</div>
                    <input class="form-control otp-input" type="text" maxlength="1" id="input4" v-on:keyup="{}">
                    <input class="form-control otp-input" type="text" maxlength="1" id="input5" v-on:keyup="{}">
                    <input class="form-control otp-input" type="text" maxlength="1" id="input6" v-on:keyup="{}">
                </div>
                <app-button @click="validateAndRegister" :title="'Verify & Proceed'" :loading="loaders().validateCode" :disabled="loaders().validateCode || code.length < 6" ></app-button>
                <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
                    <div class="error-msg">{{ key }}: {{ error }}</div>
                </div>
            </form>
            <hr/>
            <div @click="setStage(stages.CREATE_FORM)">back</div>
        </div>
        <div class="add-user" v-else-if="stage===stages.USER_FORM">
            <h6>
                Add User
                <br>
                <small>Search and select user</small>
            </h6>
            <div class="form-group">
                <label class="form-label" for="userEmailInput">Email</label>
                <input class="form-control form-control-clicked" id="userEmailInput" v-model="v$.user.email.$model" @keyup="findUser" type="text" placeholder="akande@email.com">
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.email.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class="user-list">
                <i v-if="loaders().searchUsers">Loading... </i>
                <ul v-else-if="users.length > 0">
                    <li v-for="(user, i) in users" :key="i">
                        <span :class="[user.is_blocked ? 'text-muted': '']">
                            <input type="radio" @click="selectedUser=user" :disabled="user.is_blocked" name="selected-user" /> {{ user.name }}
                        </span>
                    </li>
                </ul>
                <span v-else class="text-muted">
                    no user found yet!
                </span>
            </div>
            <div class="text-center my-1 mb-8">
                <app-button 
                @click="setStage(stages.PREVIEW)" 
                :title="'Continue'" 
                :loading="loaders().subscribe" 
                :disabled="loaders().subscribe || v$.user.email.$invalid" 
                ></app-button>
            </div>
            <hr/>
            <div @click="setStage(stages.SELECT_TYPE)">back</div>
        </div>
        <div class="add-user" v-else-if="stage===stages.PREVIEW">
            <h6>
                Preview
                <br>
                <small>Confirm user</small>
            </h6>
            <div>
                {{ selectedUser.email }}
            </div>
            <div class="text-center my-1 mb-8">
                <app-button 
                @click="setStage(stages.COMPLETED)" 
                :title="'Confirm'"
                ></app-button>
            </div>
        </div>
        <div v-else-if="stage===stages.COMPLETED">
            <div class="order-success-wrapper">
                <div class="custom-container">
                    <div class="order-done-content">
                    <svg class="bi bi-check-circle-fill text-success mb-4" width="60" height="60" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                    </svg>
                    <h5>Successful</h5>
                    <p>
                        Thank You!
                    </p>
                    <div class="">
                        
                    </div>
                    <button class="btn btn-warning mt-3 text-white" id="close-modal-id" @click="$emit('user', selectedUser)" data-bs-dismiss="modal" aria-label="Close">Done</button>
                    <!-- <button class="btn btn-close p-1 ms-auto" type="button" id="close-modal-id" @click="$emit('close', selectedUser)" data-bs-dismiss="modal" aria-label="Close">Done</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Modal from '@/components/common/modal/Modal.vue'
    import useVuelidate from '@vuelidate/core';
    import { createToaster } from "@meforma/vue-toaster";
    import AppButton from '@/components/common/form/AppButton.vue';
    import { mapGetters, mapActions } from 'vuex';
    import { required } from '@vuelidate/validators';
    const toaster = createToaster({ /* options */ });

    export default {
        name: 'AddUser',
        props: ['stopStage', 'id', 'referrer'],
        components: {
            Modal,
            AppButton
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations() {
            return {
                user: {
                    name: { required },
                    phone: { required },
                    email: { required }
                }
            }
        },
        data() {
            return {
                user: {},
                stage: "select-type",
                stages: {
                    SELECT_TYPE: 'select-type',
                    CREATE_FORM: 'create-form',
                    OTP_FORM: 'otp-form',
                    USER_FORM: 'user-form',
                    PREVIEW: 'preview',
                    COMPLETED: 'completed'
                },
                errors: {},
                code: "",
                timer: null,
                users: [],
                selectedUser: {}
            }
        },
        mounted(){
            this.inputenter()
        },
        methods: {
            ...mapGetters([
                "loaders",
                "auth"
            ]),
            ...mapActions([
                "registerUserProxy", 
                "registerUserInit",
                "validateCode",
                "searchUsers"
            ]),
            setStage(stage) {
                this.errors = {}
                if ( stage === this.stopStage ) {
                    this.$emit('user', this.selectedUser)
                    return
                }
                this.stage = stage
            },
            validateAndSendCode() {
                const self = this
                this.registerUserInit(this.user).then( r => {
                    self.setStage(self.stages.OTP_FORM)
                    setTimeout(() => {
                        self.inputenter()
                    }, 500)
                }).catch( e => {
                    const { data } = e
                    this.errors = data
                })
            },
            validateAndRegister() {
                const inputs = document.querySelectorAll('.otp-input');
                if ( this.code.length !== inputs.length ) {
                    return;
                }
                const self = this
                this.validateCode({
                    email: this.auth().email,//this.user.email,
                    code: this.code
                }).then( r => {
                    console.log(r)
                    self.registerUserProxy({
                        ...self.user,
                        referrer: self.referrer
                    }).then( res => {
                        const { data } = res
                        self.selectedUser = data;
                        self.setStage(this.stages.PREVIEW)
                    }).catch( err => {

                    })
                }).catch( e => {
                    const { data, message } = e
                    this.errors = {message}
                })
            },
            inputenter() {
                const inputs = document.querySelectorAll('.otp-input');
                this.code = "";
                const self = this
                for (let i = 0; i < inputs.length; i++) { 
                    inputs[i].addEventListener('keydown', function(event) { 
                        if (event.key==="Backspace" ) { 
                            inputs[i].value='' ; 
                            if (i !==0) inputs[i - 1].focus(); 
                        } else {
                            if (i===inputs.length - 1 && inputs[i].value !=='' ) { return true; } 
                            else if (event.keyCode> 47 && event.keyCode < 58) { 
                            inputs[i].value=event.key; 
                            if (i !==inputs.length - 1) 
                                inputs[i + 1].focus(); event.preventDefault(); 
                            } else if (event.keyCode> 64 && event.keyCode < 91) { 
                            inputs[i].value=String.fromCharCode(event.keyCode); 
                            if (i !==inputs.length - 1) 
                                inputs[i + 1].focus(); event.preventDefault(); 
                            } 
                        } 
                        self.updateOTPValue()
                    });
                }
            },
            updateOTPValue() {
                const inputs = document.querySelectorAll('.otp-input');
                this.code = "";
                for (let i = 0; i < inputs.length; i++) { 
                    const inp = inputs[i]
                    this.code+=inp.value
                }
            },
            findUser() {
                const self = this
                if ( this.timer !== null ) {
                    clearTimeout(this.timer)
                    this.timer = null
                }
                this.timer = setTimeout(function() {
                    self.searchUsers(`search=${self.user.email}`).then( res => {
                        const { data } = res
                        self.users = data
                    })
                }, 1000);
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  