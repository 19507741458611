<template>
  <div class="container">
    <div class="card bg-card-primary bg-gradient rounded-lg">
      <div class="card-body">
        <div class="colorful-tab colorful-tab-card" >
          <div class="tab-content shadow-sm p-3" id="affanTab3Content">
            <div class="tab-pane fade text-left show active" id="fiat" role="tabpanel" aria-labelledby="fiat-tab">
              <div class="d-flex justify-content-between">
                <h6 class="text-white align-items-center">
                  <div class="text-xs text-warning"><i class="bi bi-credit-card-2-back mb-1"></i> Card Number</div>
                  <span v-if="!showDetails">4499********1212</span>
                  <span v-else>
                    4499 1212 4499 1212
                  </span>
                </h6>
                <div class="align-items-end"  id="step_4">
                  <span class="text-xs text-warning" @click="showDetails=!showDetails">
                    <i 
                      class="pi" 
                      :class="{
                        'pi-eye':showDetails, 
                        'pi-eye-slash': !showDetails
                      }" 
                      style="font-size: 10px;"></i> view
                  </span>
                  <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle bg-success" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="pi pi-cog" style="font-size: 10px;"></i>
                    </button>
                    <ul class="dropdown-menu bg-green-700">
                      <li>
                        <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#freezeCardModal"><i class="pi pi-lock" style="font-size: 10px;"></i> {{ card.status === 'active' ? 'freeze': 'unfreeze' }}</a>
                        <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#blockCardModal"><i class="pi pi-ban" style="font-size: 10px;"></i> block</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <p>Exp: 12/27</p>
                </div>
                <div>
                  <p class="mb-0 badge rounded bg-success text-xm">
                    <small>
                      CVV: 
                      <span v-if="!showDetails">***</span>
                      <span v-else>
                        123
                      </span>
                    </small> 
                  </p>
                </div>
              </div>
              
              <div class="d-flex mt-2 justify-content-between">
                <div class="text-white">{{ auth.name }}</div>
                <div class="d-flex justify-content-end">
                  <img :src="brands[0].logo" width="70" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container px-0" id="step_8">
      <ul class="box-service mt-3">
        <li @click="$emit('card', 'physical')">
          <a class="stretched-link" href="#">
            <div class="icon-box bg_color_1">
              <i class="bi bi-credit-card-fill"></i>
            </div>
            <span>Physical</span>
          </a>
        </li>
        <li @click="$emit('card', 'virtual')">
          <a class="stretched-link" href="#">
            <div class="icon-box bg_color_1">
              <i class="bi bi-credit-card"></i>
            </div>
            <span>Virtual</span>
          </a>
        </li>
        <li data-bs-toggle="modal" data-bs-target="#createCardModal" @click="stage=stages.SELECT_CARD_TYPE">
          <a class="stretched-link" href="#">
            <div class="icon-box bg_color_1">
              <i class="bi bi-card-text"></i>
            </div>
            <span>New Card</span>
          </a>
        </li>
      </ul>
    </div>

    <!-- <modal title="Create Card" id="createCardModal">
      <div class="text-center"> 
        <h6>
          {{ stage?.split("_").join(" ") }}
        </h6>
      </div>
      <div class="text-center" v-if="stage === stages.SELECT_CARD_TYPE">
        <button class="btn m-1 btn-sm btn-warning shadow" @click="selectType('physical')">
          Physical Card
        </button>
        <button class="btn m-1 btn-sm btn-warning shadow" @click="selectType('virtual')">
          Virtual Card
        </button>
      </div>
      <div class="text-center" v-else-if="stage === stages.SELECT_CARD_BRAND">
        <div class="d-flex justify-content-between">
          <div v-for="(brand) in brands" @click="selectBrand(brand)">
            <img :src="brand.logo" width="70">
            <p>{{ brand.name }}</p>
          </div>
        </div>
      </div>
      <div class="" v-else-if="stage === stages.CONFIRM_CARD_REQUEST">
        <div>
          <p class="mb-0 text-muted">
            <span>You will be charged: <strong>{{ formatter("NGN").format(1000) }}</strong></span>
            <span class="spinner-border spinner-border-sm text-warning" role="status">
              <span class="sr-only"></span>
            </span>
          </p>
          <div class="text-center my-1">
            <app-button 
              @click="stage=stages.CARD_REQUEST_RECEIPT" 
              :classes="'my-1 btn-warning w-100 shadow'" 
              :title="'Continue'" 
              :loading="loaders.generateCard || loaders.freezeCard" 
              :disabled="loaders.generateCard || loaders.freezeCard || v$.card.$invalid" 
            ></app-button>
          </div>

        </div>
        <hr/>
        <span @click="stage=stages.SELECT_CARD_BRAND" class="cursor-pointer">Back</span>
      </div>

      <div class="card invoice-card shadow" v-else-if="stage === stages.CARD_REQUEST_RECEIPT">
        <div class="card-body">
          <div class="download-invoice text-end mb-3">
            <a class="btn btn-sm btn-primary me-2" hid="#" @click="printDiv('invoice-div')">
              <i class="bi bi-file-earmark-pdf"></i> PDF
            </a>
            <a class="btn btn-sm btn-light" hid="#" @click="printDiv('invoice-div')">
              <i class="bi bi-printer"></i> Print
            </a>
          </div>

          <div id="invoice-div">
            <div class="invoice-info text-end mb-4">
              <h5 class="mb-1 fz-14">Card request</h5>
              <h6 class="fz-12">Card Type: {{ card.brand }} {{ card.type }} card</h6>
              <p class="mb-0 fz-12">Date: {{ moment(new Date).format("DD MMM, YYYY") }} </p>
            </div>

            <div class="invoice-table">
              <div class="table-responsive">
                <table class="table table-bordered caption-top">
                  <caption>Summary</caption>
                  <thead class="table-light">
                    <tr>
                      <th>Sl.</th>
                      <th>Description</th>
                      <th>Deliver</th>
                      <th>Q.</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Request for NGN {{ card.brand }} {{ card.type }} card</td>
                      <td> NGN 1000</td>
                      <td>1</td>
                      <td>NGN 1000</td>
                    </tr>
                  </tbody>
                  <tfoot class="table-light">
                    <tr>
                      <td class="text-end" colspan="4">Total:</td>
                      <td class="text-end">NGN 1000</td>
                    </tr>
                    <tr>
                      <td class="text-end" colspan="4">Fee (7.5%):</td>
                      <td class="text-end">NGN 75</td>
                    </tr>
                    <tr>
                      <td class="text-end" colspan="4">Grand Total:</td>
                      <td class="text-end">NGN 1075</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div class="text-center my-1">
            <app-button @click="stage=stages.CARD_REQUEST_AUTHORIZE" 
              :classes="'my-1 btn-warning w-100 shadow'" :title="'Request Card'" 
              :loading="loaders.generateCard || loaders.freezeCard" 
              :disabled="loaders.generateCard || loaders.freezeCard || v$.card.$invalid" 
            ></app-button>
          </div>

          <p class="mb-0">Notice: This is auto generated invoice.</p>
        </div>
      </div>
      <verify-transaction-pin v-if="stage === stages.CARD_REQUEST_AUTHORIZE" :currency="`NGN`" @verified="handleCardRequestVerification"></verify-transaction-pin>
    </modal> -->

    <modal title="Create Card" id="createCardModal">
      <template v-if="!feedback">
        <div class="text-center"> 
          <h6>Confirm you want to create card</h6>
        </div>
        <div class="text-center">
          feature will be available soon
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn m-1 btn-sm btn-success shadow" @click="testUpComingFeature('like-create-card-feature')">
              We want this feature
            </button>
            <button class="btn m-1 btn-sm btn-warning shadow" @click="testUpComingFeature('dislike-create-card-feature')">
              We don't want it
            </button>
        </div>
      </template>
      <success-notification v-else title="Feedback Recieved" message="Thank you for the feedback. We would review your response"></success-notification>
    </modal>

    <modal title="Block Card" id="freezeCardModal">
      <template v-if="!feedback">
        <div class="text-center"> 
          <h6>Confirm you want to {{ card.status === 'active' ? 'freeze': 'unfreeze' }} card</h6>
        </div>
        <div class="text-center">
          feature will be available soon
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn m-1 btn-sm btn-success shadow" @click="testUpComingFeature('like-freeze-card-feature')">
              We want this feature
            </button>
            <button class="btn m-1 btn-sm btn-warning shadow" @click="testUpComingFeature('dislike-freeze-card-feature')">
              We don't want it
            </button>
        </div>
      </template>
      <success-notification v-else title="Feedback Recieved" message="Thank you for the feedback. We would review your response"></success-notification>
    </modal>

    <modal title="Block Card" id="blockCardModal">
      <template v-if="!feedback">
        <div class="text-center"> 
          <h6>Confirm you want to block card</h6>
        </div>
        <div class="text-center">
          feature will be available soon
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn m-1 btn-sm btn-success shadow" @click="testUpComingFeature('like-block-card-feature')">
              We want this feature
            </button>
            <button class="btn m-1 btn-sm btn-warning shadow" @click="testUpComingFeature('dislike-block-card-feature')">
              We don't want it
            </button>
        </div>
      </template>
      <success-notification v-else title="Feedback Recieved" message="Thank you for the feedback. We would review your response"></success-notification>
    </modal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Modal from '@/components/common/modal/Modal.vue'
import ProductPackage from '@/components/common/guard/ProductPackage.vue'
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AppButton from '@/components/common/form/AppButton.vue';
import VerifyTransactionPin from '@/components/common/wallet/VerifyTransactionPin.vue';
// import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import { createToaster } from "@meforma/vue-toaster";
import { printDiv, formatter } from '@/utils';
import moment from 'moment'
import { defineComponent } from 'vue'
import SuccessNotification from '@/components/common/notification/SuccessNotification.vue'

import mastercard from "@/assets/images/card/mastercard.png";
import visacard from "@/assets/images/card/visa.png";
import vervecard from "@/assets/images/card/verve.png";

const toaster = createToaster({ /* options */ });

export default defineComponent({
  name: 'Balance',
  components: {
    Modal,
    AppButton,
    VerifyTransactionPin,
    SuccessNotification,
    ProductPackage
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      card: {
        type: { required },
        brand: { required },
        name: { required },
      }
    }
  },
  data() {
    return {
      brands: [
        {name: 'Verve Card', logo: vervecard, value: 'verve'},
        {name: 'Visa Card', logo: visacard, value: 'visa'},
        {name: 'Master Card', logo: mastercard, value: 'mastercard'},
      ],
      feedback: false,
      showDetails: false,
      baseCurrency: "NGN",
      errors: {},
      stage: "",
      stages: {
        SELECT_CARD_TYPE: "select_card_type",
        SELECT_CARD_BRAND: "select_card_brand",
        CONFIRM_CARD_REQUEST: "confirm_card_request",
        PAY_CARD_REQUEST_FEE: "pay_card_request_fee",
        CARD_REQUEST_RECEIPT: "card_request_receipt",
        CARD_REQUEST_AUTHORIZE: "card_request_authorize"
      },
      card: {},
      chat: null,
    }
  },
  created() {
    this.moment = moment;
    this.printDiv = printDiv;
    this.formatter = formatter
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      "loaders",
      "auth"
    ])
  },
  watch: {
  },
  methods: {
    ...mapActions(["voteFeature"]),
    selectType(type) {
      this.card.type = type;
      this.stage = this.stages.SELECT_CARD_BRAND
    },
    selectBrand(brand) {
      this.card.type = brand.value;
      this.stage = this.stages.CONFIRM_CARD_REQUEST
    },
    handleCardRequestVerification(verified) {
      if (!verified) {
        return
      }
      this.stage = this.stages.CARD_REQUEST_RECEIPT;
      this.transferFiat()
    },
    async testUpComingFeature(response) {
      try {
        await this.voteFeature(response)
        this.feedback = true
      } catch (e) {
        toaster.error("Ops!, something went wrong, please try again.")
      }
    }
  }
})
</script>

<style scoped>
.bg-card-primary {
  --bs-bg-opacity: 1;
  background-color: var(--jw-text-success, var(--bs-bg-opacity)) !important;
}

.wallet-icon>svg>path, .wallet-icon>svg>g>path{
  fill: rgba(var(--bs-success-rgb));
}

.nav-item {
  padding: 2px 0px
}

.nav-item button {
  padding: 6px 0px
}

.colorful-tab .tab-content {
  background-color: rgba(0, 0, 0, 0) !important
}

.colorful-tab .btn-warning, .shepherd-button, .bg_color_1 {
  background-color: var(--warning-color) !important;
  border-color: var(--warning-color) !important;
  background: var(--warning-color) !important;
  columns: var(--primary-color) !important;
}

.input-group-text.select-input {
  padding: 0rem;
  border: 0px solid #ced4da;
}

.colorful-tab .btn.btn-warning {
  color: var(--primary-color) !important;
}

.colorful-tab-card .btn {
  font-size: 12px;
}

.vas a {
  text-decoration: none !important;
}
.vas .link {
  color: var(--jw-text-success) !important;
}
.btn-primary:hover {
    color: #fff;
    /* background-color: #0b5ed7; */
    background-color: rgba(var(--bs-success-rgb));
    border-color: #0a58ca;
}
.dropdown-menu.show {
    background-color: rgba(var(--bs-success-rgb));
    color: white;
}
.dropdown-menu.show li a{
    color: white;
}
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}


@import url('https://fonts.googleapis.com/css?family=Lato');
#pannel {
  background: url('https://www.shutterstock.com/image-vector/social-media-sketch-vector-seamless-600nw-1660950727.jpg');
  background-size: cover;
  background-position: center	center;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: 'Lato', sans-serif;
  margin: 0 0;
  height: 100%;
  overflow: hidden;
}
.pannel-box .controls {
  top: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  z-index: 1000;
  font-size: 10px;
}
.pannel-box .controls button {
  color: white;
  background-color: rgb(235, 12, 12);
  border: none;
  padding: 2px 10px;
  border-radius: 1px;
}
.pannel-box .controls span {
  padding: 2px 10px;
  font-size: 12px;
}
.pannel-box .chat-container {
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  padding: 15px;
  position: relative;
  height: 356px;
  max-width: 100%;
  width: 100%;
}
.pannel-box .chat-container .chat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.pannel-box .chat-container .chat::-webkit-scrollbar {
  display: none;
}
.pannel-box .chat-container .chat .message {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  box-shadow: 0px 15px 5px 0px rgba(0,0,0,0.5);
  position: relative;
  margin-bottom: 15px;
  font-size: 12px;
  max-width: 100%;
}
.pannel-box .chat-container .chat .message.sending {
  background-color: rgba(210, 210, 210, 0.9);
}
.pannel-box .chat-container .chat .message.error{
  background-color: rgba(255, 210, 210, 0.9);
}
.pannel-box .chat-container .chat .message.left{
  padding: 10px 20px 10px 50px;
}
.pannel-box .chat-container .chat .message.left small {
  margin-left: 0.5rem;
  color: #999;
  font-size: 8px;
}
.pannel-box .chat-container .chat .message.right {
  align-self: flex-end;
  padding: 10px 50px 10px 20px;
}
.pannel-box .chat-container .chat .message.right .logo {
  left: auto;
  right: 10px;
}
.pannel-box .chat-container .chat .message.right small {
  margin-right: 0.5rem;
  color: #999;
  font-size: 8px;
}
.pannel-box .chat-container .chat .message .logo {
  border-radius: 50%;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.7);
  object-fit: cover;
  position: absolute;
  left: 10px;
  top: -10px;
  width: 32px;
  height: 32px;
}
.pannel-box .chat-container .chat .message p {
  margin: 0;
}
.pannel-box .chat-container .chat .message .message-file {
  cursor: pointer;
}
.pannel-box .form {
  position: relative;
  width: 100%;
}
.pannel-box .form .text_input {
  font-size: 16px;
  position: relative;
  left: 0;
  right: 0;
  padding: 10px 15px;
  padding-right: 40px;
  width: 100%;
  color: #666;
}
.pannel-box .form .message-control {
  position: absolute;
  top: 6px;
  right: 10px;
}
.pannel-box .form .message-control .attachment {
  display: none;
}
.pannel-box {
  width: 100%;
  position: relative;
}
</style>
