<template>
  <page-template>
    <div class="page-content-wrapper py-0">
      <div class="card cta-card bg-img bg-overlay pb-4 fixed" :style="{'background-image': `url(${banner})`, 'z-index': '0'}">
        <div class="container">
          <div class="card-body px-4 pb-5">
            <h6 class="mb-3 mt-2 text-white text-capitalize">
              <i class="pi" :class="salutationIcon"></i> {{ salutation }}, 
              <span v-if="Object.values(auth()).length > 0" class="text-capitalize">
                <span v-if="visibility==='no'" class="blur-white">xxxxxxxxxxx</span>
                <span v-else style="color: #8ac4fe;">{{ auth().name }}</span>
              </span>
              <div v-else class="spinner-border spinner-border-sm text-warning" role="status">
                <span class="sr-only"></span>
              </div>
              <span @click="toggleVisibility(visibility === 'no' ? 'yes': 'no')" class="mx-2">
                <i :class="`pi ${visibility === 'no' ? 'pi-eye-slash': 'pi-eye'}`"></i> 
              </span>
            </h6>
            <div class="my-2 d-flex justify-content-between" role="group" aria-label="main action button">
              <span class="text-capitalize text-white">
                <i class="pi mx-2"
                  :class="{
                    'pi-building-columns': activeAccount === ACCOUNT_TYPES.HOME,
                    'pi-wave-pulse': activeAccount === ACCOUNT_TYPES.INVESTMENT,
                    'bi bi-piggy-bank': activeAccount === ACCOUNT_TYPES.SAVINGS,
                    'pi-credit-card': activeAccount === ACCOUNT_TYPES.LOAN
                  }"
                ></i> {{ activeAccount }}
              </span>
              <!-- <router-link v-if="ACCOUNT_TYPES.INVESTMENT === activeAccount" class="btn btn-info btn-sm cta-btn" to="/plans">Start Investing</router-link>
              <router-link v-if="ACCOUNT_TYPES.SAVINGS === activeAccount" class="btn btn-success btn-sm cta-btn" to="/saves">Start Saving</router-link>
              <router-link v-if="ACCOUNT_TYPES.LOAN === activeAccount" class="btn btn-error btn-sm cta-btn" to="/loans">Take Loan</router-link> -->
              <div class="d-flex justify-content-end flex-column cta-btn">
                <span 
                  class="bg-success text-sm text-white rounded px-2 py-1 cursur-pointer mb-1" 
                  v-if="accountDetails && accountDetails.account_number" 
                  @click="clickToCopy(accountDetails.account_number)">
                  {{ accountDetails.bank_name }}: {{ accountDetails.account_number }} <i class="pi pi-clone" style="font-size: 9px;"></i>
                </span>
                <router-link 
                  v-else 
                  class="btn btn-success btn-sm cta-btn mb-1" 
                  :to="{ path: 'bank-transfer'}">
                  <i class="pi pi-bolt" style="font-size: 9px;"></i> Generate Account
                </router-link>

                <router-link class="btn btn-success btn-sm cta-btn" :to="{ path: 'services', query: { type: 'airtime' }}"><i class="pi pi-receipt" style="font-size: 9px;"></i> Pay Bills</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-section">
        <balance @page-change="(_) => loadTransaction()"></balance>
        <Transition name="bounce">
          <transaction-pin></transaction-pin>
        </Transition>

        <Transition name="slide-fade">
          <prediction v-if="ACCOUNT_TYPES.INVESTMENT === activeAccount"></prediction>
        </Transition>

        <TransitionGroup name="bounce">
          <plan-list v-if="ACCOUNT_TYPES.INVESTMENT === activeAccount"></plan-list>
          <loan-list v-if="ACCOUNT_TYPES.LOAN === activeAccount"></loan-list>
          <save-list v-if="ACCOUNT_TYPES.SAVINGS === activeAccount"></save-list>
          <div class="notification-area mt-4" v-if="ACCOUNT_TYPES.HOME === activeAccount">
            <div class="container">
              <transaction-list :transactions="transactions().data || []"></transaction-list>
              <div class="d-flex justify-content-center">
              <router-link class="btn m-1 btn-sm btn-warning shadow" to="/transaction">
                see more
              </router-link>
            </div>
            </div>
          </div>
        </TransitionGroup>

        <div class="my-6 py-2"></div>
      </div>

      <modal title="Payment Method" id="save-usd-modal">
        <div class="text-center"> 
          <h6>We preserve your naira in USD value.</h6>
        </div>
        <div class="text-center">  
          <form>
            <div class="form-group">
              <label class="form-label" for="fund-amount">Amount (NGN)</label>
              <input class="form-control form-control-clicked" id="fund-amount" type="number" placeholder="100" v-model="v$.fund_amount.$model" >
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fund_amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <router-link 
              class="my-1 btn btn-warning w-100 shadow" 
              v-if="!v$.fund_amount.$invalid"
              :to="{ path: 'saves', query: { amount: fund_amount, funding_source: 'bank', name: 'Preserve my Naira Funds', plan: usd_plan_id }}"
              @click="handleCloseSaveModal"
            >continue</router-link>
          </form>
        </div>
      </modal>
    </div>
  </page-template>
</template>

<script>
// @ is an alias to /src
import TransactionList from '@/components/pages/transaction/TransactionList.vue'
import TransactionPin from '@/components/common/wallet/TransactionPin.vue'
import ProductPackage from '@/components/common/guard/ProductPackage.vue'
import { ACCOUNT_TYPES } from "../../../store/states/account.state"
import PageTemplate from '@/components/templates/PageTemplate.vue'
import bootstrapBundleMin from '@/assets/js/bootstrap.bundle.min';
import Prediction from '@/components/common/coin/Prediction.vue'
import Balance from '@/components/common/wallet/Balance.vue'
import PlanList from '@/components/common/plan/PlanList.vue'
import SaveList from '@/components/common/save/SaveList.vue'
import LoanList from '@/components/common/loan/LoanList.vue'
import { required, minLength } from '@vuelidate/validators';
import Modal from '@/components/common/modal/Modal.vue'
import { bankTransfer, strings } from '@/mixins';
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core';
import { USD_SAVINGS_ID } from '@/utils';

export default {
  name: 'Dashboard',
  components: {
    PageTemplate,
    Balance,
    Prediction,
    PlanList,
    SaveList,
    LoanList,
    TransactionList,
    Modal,
    TransactionPin,
    ProductPackage
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      fund_amount: { required, min: minLength(3) },
    }
  },
  mixins: [bankTransfer, strings],
  data() {
    const { banner } = this.appConfig() || {};
    return {
      fund_amount: null,
      ACCOUNT_TYPES,
      banner: banner || require("@/assets/images/common/22.jpeg"),
      usd_plan_id: USD_SAVINGS_ID
    }
  },
  computed: {
    salutation() {
      const date = new Date;
      const hours = date.getHours();
      return "Good " + ((hours < 12) ? "Morning" : ((hours <= 18 && hours >= 12 ) ? "Afternoon" : "Night"));
    },
    salutationIcon() {
      const date = new Date;
      const hours = date.getHours();
      return (hours < 12) ? "pi-sun" : ((hours <= 18 && hours >= 12 ) ? "pi-moon" : "pi-sparkles");
    },
    ...mapGetters(["visibility", "activeAccount"])
  },
  mounted() {
    this.getAuthUser();
    this.loadTransaction()
  },
  methods: {
    ...mapActions([
      "getAuthUser",
      "getTransactions",
      "toggleVisibility"
    ]),
    ...mapGetters([
      "auth",
      "transactions",
      "appConfig"
    ]),
    handleCloseSaveModal() {
      const saveUSDModal = document.getElementById('save-usd-modal');
      if (saveUSDModal) {
        const _saveUSDModal = bootstrapBundleMin.Modal.getInstance(saveUSDModal)
        _saveUSDModal.hide();
      }
    },
    loadTransaction() {
      if ( ACCOUNT_TYPES.HOME === this.activeAccount ) {
        this.getTransactions({})
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn.btn-warning {
  color: var(--primary-color) !important;
}
.cta-card {
  margin: 0px -16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  /* border-bottom-right-radius: 80px;
  border-bottom-left-radius: 80px; */
  overflow: hidden;
  margin-bottom: 9px;
}
.card-section {
  position: relative;
  margin-top: -63px;
  /* z-index: 1; */
}

.cta-btn {
  font-size: 10px;
}


</style>
