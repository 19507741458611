import { GetTenant, AppLog } from "@/services/app"

export const fetchTenant = (context) => {
    context.commit("updateLoader", { fetchTenant: true });
    return new Promise((resolve, reject) => {
        GetTenant()
            .then(res => {
                const data = res.data.data;
                context.commit("fetchTenant", data);
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchTenant: false });
            });
    });
};

export const voteFeature = (context, feature) => {
    const payload = {
        type: "feature-vote",
        response: feature
    }
    context.dispatch('appLog', payload)
}

export const appLog = (context, data) => {
    context.commit("updateLoader", { appLog: true });
    return new Promise((resolve, reject) => {
        AppLog(data)
            .then(res => {
                const data = res.data.data;
                resolve(data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { appLog: false });
            });
    });
};

const AppAction = {
    fetchTenant,
    appLog,
    voteFeature
};

export default AppAction;