export const ACTION_TYPE = {
    Add: "Add",
    Edit: "Edit"
}

export const ENVIRONMENT = process.env.NODE_ENV
export const IS_PRODUCTION = ENVIRONMENT === "production"
// export const BASE_URL = IS_PRODUCTION ? "https://api.empowerlenders.com/" : "http://jawo.local/";
export const BASE_URL = IS_PRODUCTION ? "https://api.redge.ng/" : "http://jawo.local/";
// export const BASE_URL = IS_PRODUCTION ? "https://api.redge.ng/" : "http://localhost:8000";
export const ASTRO_PAY_APP_ID = "PbXuYJFEKLNMleTXz1jRkADgYFisgCMnr7k8LSsJVA7Am9giVkixBNHxoo16azxA"
export const PUSHER_APP_KEY = IS_PRODUCTION ? "024f3c8faacbde13a57a" : "90861b8f263129ad9082"; //
export const PUSHER_APP_CLUSTER = 'eu'
export const REVERB_APP_KEY = '90861b8f263129ad9082'
export const WS_HOST = IS_PRODUCTION ? 'socket.empowerlenders.com' : 'localhost';
export const WS_PORT = IS_PRODUCTION ? 443 : 80
    // export const WS_PORT = 6001
export const FLUTTERWAVE_PUB_KEY = IS_PRODUCTION ? 'FLWPUBK-5821f743ec9cdd6b6dd0f583a8caf4ec-X2' : 'FLWPUBK_TEST-a204d1f3fc9b2aa7e18ce907985d46bd-X2'
export const OPEN_REPLAY_KEY = "MNSe2VNKPE0AsggibwtJ"
export const USD_SAVINGS_ID = IS_PRODUCTION ? 7 : 5;
export const VAPID_KEY = "BOQeAyAlgYqCE8ZBoaRwIaIj4jcmzZ2EGcR8SyNq3ntEQ8Km-SJsIanI6k9XmcNRXzZtnQG4n1-2ZX_zIQ7-LDY"

export const PLAN_TYPES = {
    savings: "savings", 
    loan: "loan", 
    crypto: "crypto", 
    stock: "stock", 
    forex: "forex", 
    nft: "nft", 
    estate: "estate",
    invesment: "investment"
}


export const transactionList = [
    "",
    "plan-subscription", 
    "alert-subscription", 
    "loan-subscription",
    "loan-request-fee",
    "loans-payment",
    "wallet-transfer",
    "savings-payment",
    "airtime-topup",
    "data-topup",
    "cable-tv",
    "power",
    "refund", 
    "funding",  
    "start-funding",
    "withdraw",
    "commission",
    "address-verification",
    "stamp-duty",
    "data-subscription",
]

export const statusList = [
    '', 
    'INITIATED',
    'PENDING', 
    'FAILED', 
    'ACTIVE', 
    'DECLINED', 
    'RETRY', 
    'COMPLETED', 
    'WITHDRAWN', 
    'APPROVED', 
    'DUE', 
    'REVERSED', 
    'DEFAULT'
]
