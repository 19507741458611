<template>
  <centralized-template>
    <div class="login-back-button">
      <router-link to="#" @click="goBack">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </router-link>
    </div>
    <div class="login-wrapper d-flex align-items-center justify-content-center">
      <div class="custom-container">
        <div class="text-center"><img class="mx-auto mb-4 d-block" src="../../../assets/images/auth/38.png" alt="">
          <h3>Verify Account</h3>
          <p v-if="query.message" class="mb-4">{{ query.message }}</p>
          <p v-else class="mb-4">Enter the OTP code sent to<strong class="ms-1 text-success">your phone or email</strong></p>
        </div>
        <!-- OTP Verify Form -->
        <div class="otp-verify-form mt-4">
          <form>
            <div class="input-group mb-3 otp-input-group" id="otp">
              <input class="form-control" type="text" maxlength="1" id="input1" v-on:keyup="inputenter(1)">
              <input class="form-control" type="text" maxlength="1" id="input2" v-on:keyup="inputenter(2)">
              <input class="form-control" type="text" maxlength="1" id="input3" v-on:keyup="inputenter(3)">
              <div class="pt-2">&nbsp;--&nbsp;</div>
              <input class="form-control" type="text" maxlength="1" id="input4" v-on:keyup="inputenter(4)">
              <input class="form-control" type="text" maxlength="1" id="input5" v-on:keyup="inputenter(5)">
              <input class="form-control" type="text" maxlength="1" id="input6" v-on:keyup="inputenter(6)">
            </div>

            <app-button 
              @click="verify"
              :title="'Verify & Proceed'" 
              :loading="loaders().forgotPassword || loaders().authCode || loaders().verifyUser || loaders().identityRegistrationCreate || loaders().identityRegistrationInit" 
              :disabled="loaders().forgotPassword || loaders().authCode || loaders().verifyUser || loaders().identityRegistrationCreate || loaders().identityRegistrationInit || !isCodeValid" 
              ></app-button>
            </form>
        </div>
        <!-- Term & Privacy Info -->
        <div class="login-meta-data text-center">
          <p class="mt-3 mb-0">
            Din't received the OTP?
            <span class="otp-sec ms-1 text-warning" id="resendOTP" v-if="count > 0">Wait {{ count }} {{ count === 1 ? 'sec' : 'secs'}}</span>
            <span class="otp-sec ms-1 text-success cursor-pointer" to="#" v-else @click="resend">resend code</span>
          </p>
        </div>

        <div class="mt-4">
          <ul>
            <li v-for="(error, i) in errors" :key="i" class="text-danger text-xs" > {{ Array.isArray(error) ? error.join(', ') : error }} </li>
          </ul>
        </div>
      </div>
    </div>
  </centralized-template>
</template>

<script>
// @ is an alias to /src
import CentralizedTemplate from '@/components/templates/CentralizedTemplate.vue';
import AppButton from '@/components/common/form/AppButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OtpConfirmPage',
  components: {
    CentralizedTemplate,
    AppButton
  },
  data() {
    return {
      COUNT_TIME: 45,
      count: 45,
      code: "",
      errors: {},
      query: {}
    }
  },
  computed: {
    isCodeValid() {
      const inputs = document.querySelectorAll('#otp > *[id]');
      for (let i = 0; i < inputs.length; i++) { 
        if ( !inputs[i].value || inputs[i].value === '') { 
          return false
        }
      }
      return true;
    }
  },
  methods: {
    ...mapActions([
      "forgotPassword",
      "validateCode",
      "authCode",
      "verifyUser",
      "identityRegistrationCreate",
      "identityRegistrationInit",
      "identityAuthInit",
      "identityAuthCreate",
      "authUserWithOTP"
    ]),
    ...mapGetters([
      "loaders",
      "auth"
    ]),
    countdown () {
      this.count = this.COUNT_TIME;
      setTimeout( () => {
        clearInterval(intv);
      }, (this.count * 1000 + 1000));

      const intv = setInterval( () => {
        this.count-=1;
      }, 1000);
    },
    resend() {
      const { email, page, referrer, nin, bvn } = this.query;
      const data = {
        email,
        page, referrer, nin, bvn
      };
      if ( ! data.email && this.auth().email ) {
        data.email = this.auth().email
      }

      let req;
      switch (page) {
        case 'otp-login':
        case 'validate':
          req = this.authCode(data);
          break;
        case 'identity-registration':
          req = this.identityRegistrationInit(data);
          break;
        case 'identity-auth':
          req = this.identityAuthInit(data);
          break;
        default:
          req = this.forgotPassword(data);
          break;
      }
      const self = this;
      req.then( function (res) {
        self.countdown();
        const { message } = res;
        if ( message ) {
          self.query = {
            ...self.query,
            message
          }
        }
      }).catch ( err => {
        const { message, data = {} } = err
        this.errors = { message } || data
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    inputenter(id) {
      const inputs = document.querySelectorAll('#otp > *[id]');
      this.code = "";
      for (let i = 0; i < inputs.length; i++) { 
        inputs[i].addEventListener('keydown', function(event) { 
          if (event.key==="Backspace" ) { 
            inputs[i].value='' ; 
            if (i !==0) inputs[i - 1].focus(); 
          } else {
            if (i===inputs.length - 1 && inputs[i].value !=='' ) { return true; } 
            else if (event.keyCode> 47 && event.keyCode < 58) { 
              inputs[i].value=event.key; 
              if (i !==inputs.length - 1) 
                inputs[i + 1].focus(); event.preventDefault(); 
            } else if (event.keyCode> 64 && event.keyCode < 91) { 
              inputs[i].value=String.fromCharCode(event.keyCode); 
              if (i !==inputs.length - 1) 
                inputs[i + 1].focus(); event.preventDefault(); 
            } 
          } 
        }); 
        this.code+=inputs[i].value;
      }
      if ( this.code.length === inputs.length ) {
        this.verify();
      }
    },
    verify() {
      this.errors = {};
      const code = this.code.slice(0, 3) + "-" + this.code.slice(3)
      const { email, page, referrer, nin, bvn } = this.query;
      const data = {
        code,
        email,
        referrer, 
        nin, 
        bvn
      };

      if ( ! data.email && this.auth().email ) {
        data.email = this.auth().email
      }

      let req;
      switch (page) {
        case 'validate':
          req = this.verifyUser(data);
          break;
        case 'identity-registration':
          req = this.identityRegistrationCreate(data);
          break;
        case 'identity-auth':
          req = this.identityAuthCreate(data);
          break;
        case 'otp-login':
          req = this.authUserWithOTP(data)
          break;
        default:
          req = this.validateCode(data);
          break;
      }

      const self = this;
      req.then( res => {
        if ( page === 'validate' ) {
          window.localStorage.setItem("verified", true);
          self.$router.push({ 
            path: '/verified'
          });
        } else if (page === "identity-auth" || page === "identity-registration" || page === "otp-login" ) {
          self.$router.push('/dashboard');
        } else {
          self.$router.push({ 
            path: '/reset-password',
            params: data,
            query: data
          });
        }
      }).catch ( err => {
        const { message, data = {} } = err
        this.errors = { message } || data
      })
    }
  },
  mounted() {
    this.countdown();
    this.inputenter(1)
    this.query = this.$route.query || this.$route.params || {};
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
