export const fetchDisputes = (
    state, disputes
) => {
    state.disputes = disputes;
}

export const saveDispute = (state, dispute) => {
    const disputes = [
        ...state.disputes.data,
        dispute
    ];
    state.disputes.data = disputes;
};

export const updateDispute = (state, dispute) => {
    const currIdx = state.disputes.data.findIndex(
        (f) => f.id === dispute.id
    );
    if (currIdx < 0) {
        return;
    }
    state.disputes[currIdx] = dispute;
};

export const deleteDispute = (state, dispute) => {
    const disputes = state.disputes.data.filter(
        f => f.id !== dispute.id
    );
    state.disputes.data = disputes;
};


const DisputeMutation = {
    fetchDisputes,
    saveDispute,
    updateDispute,
    deleteDispute
};

export default DisputeMutation;