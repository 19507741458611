import {
    FetchDisputes,
    SaveDispute,
    UpdateDispute,
    ToggleDispute,
    DeleteDispute
} from "@/services/dispute";

export const fetchDisputes = (context, data = {}) => {
    context.commit("updateLoader", { fetchDisputes: true });
    return new Promise((resolve, reject) => {
        FetchDisputes(data)
            .then(res => {
                const result = res.data.data
                if (data && data.get_csv) {
                    resolve(result)
                } else {
                    context.commit("fetchDisputes", result);
                    resolve(result)
                }
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchDisputes: false });
            });
    });
}

export const saveDispute = (context, data) => {
    context.commit("updateLoader", { saveDispute: true });
    return new Promise((resolve, reject) => {
        SaveDispute(data)
            .then(res => {
                const result = res.data
                context.commit("saveDispute", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { saveDispute: false });
            });
    });
}

export const updateDispute = (context, data) => {
    context.commit("updateLoader", { updateDispute: true });
    return new Promise((resolve, reject) => {
        const id = data.id;
        delete data.id;
        UpdateDispute(id, data)
            .then(res => {
                const result = {...res.data, id}
                context.commit("updateDispute", result);
                resolve(result)
            })
            .catch(err => {
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateDispute: false });
            });
    });
}

export const toggleDispute = (context, data) => {
    context.commit("updateLoader", { toggleDispute: true });
    return new Promise((resolve, reject) => {
        const id = data.id;
        delete data.id;
        ToggleDispute(id, data)
            .then(res => {
                const result = {...res.data, id}
                context.commit("updateDispute", result);
                resolve(result)
            })
            .catch(err => {
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { toggleDispute: false });
            });
    });
}

export const deleteDispute = (context, data) => {
    context.commit("updateLoader", { deleteDispute: true });
    const { id } = data;
    return new Promise((resolve, reject) => {
        DeleteDispute(id)
            .then(res => {
                const result = res.data.data
                context.commit("deleteDispute", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteDispute: false });
            });
    });
}

const DisputeAction = {
    fetchDisputes,
    saveDispute,
    updateDispute,
    toggleDispute,
    deleteDispute
};

export default DisputeAction;