<template>
  <div> 
    <h6>
        Authorize
    </h6>
    <div class="form-group">
        <label class="form-label" for="pin">Enter Transaction Pin</label>
        <input class="form-control form-control-clicked" type="password" inputmode="numeric" pattern="[-+]?[0-9]*[.,]?[0-9]+" id="pin" v-model="v$.pin.$model" placeholder="0000">
        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.pin.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
        </div>
    </div>
    <div class="text-center my-1 mb-8">
        <app-button 
        @click="verifyPin" 
        :title="'Confirm'" 
        :loading="loaders().confirmPin" 
        :disabled="loaders().confirmPin || v$.pin.$invalid" 
        ></app-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import AppButton from '@/components/common/form/AppButton.vue';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  name: 'VerifyTransactionPin',
  props: ['currency'],
  components: {
    AppButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      pin: { required, min: minLength(4), max: maxLength(4) },
    }
  },
  data() {
    return {
      pin: ""
    }
  },
  methods: {
    ...mapActions([
      "confirmPin",
    ]),
    ...mapGetters([
      "loaders"
    ]),
    verifyPin() {
      this.confirmPin({pin: this.pin, currency: this.currency}).then( r => {
        toaster.success("Transaction pin validated!");
        const base64Pin = btoa(this.pin);
        window.localStorage.setItem("tp", base64Pin)
        const self = this;
        setTimeout(() => {
          self.$emit("verified", true)
        }, 700)
        setTimeout(() => {
          window.localStorage.removeItem("tp")
        }, 3000);
      }).catch( e => {
        const { message = "Ops, something went wrong!" } = e;
        toaster.error(message)
        this.$emit("verified", false)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
