import { createRouter, createWebHistory } from 'vue-router';
import FirstPage from '../components/pages/splash/FirstPage.vue';
import SecondPage from '../components/pages/splash/SecondPage.vue';
import LoginPage from '../components/pages/auth/LoginPage.vue';
import RegisterPage from '../components/pages/auth/RegisterPage.vue';
import ForgotPassword from '../components/pages/auth/ForgotPassword.vue';
import ResetPassword from '../components/pages/auth/ResetPassword.vue';
import OtpPage from '../components/pages/auth/OtpPage.vue';
import OtpConfirmPage from '../components/pages/auth/OtpConfirmPage.vue';
import SuccessNotification from '../components/pages/notification/SuccessNotification.vue';
import AccountVerified from '../components/pages/auth/AccountVerified.vue';
import SelectApp from '../components/pages/auth/SelectApp.vue';
import ProfilePage from '../components/pages/settings/ProfilePage.vue';
import VerificationPage from '../components/pages/settings/VerificationPage.vue';
import QuestionPage from '../components/pages/auth/QuestionPage.vue';
import SecurityQuestionPage from '../components/pages/settings/SecurityQuestionPage.vue';
import CardPage from '../components/pages/card/CardPage.vue';
import Transaction from '../components/pages/transaction/Transaction.vue';
import ReferralTransaction from '../components/pages/transaction/ReferralTransaction.vue';
import Vas from '../components/pages/vas/Vas.vue';
import VasServices from '../components/pages/vas/VasService.vue';
import PlanList from "../components/pages/plan/PlanList.vue";
import SaveList from "../components/pages/save/SaveList.vue";
import LoanList from "../components/pages/loan/LoanList.vue";
import UserLoanList from "../components/pages/loan/UserLoanList.vue";
import LoanQuestionPage from '../components/pages/loan/LoanQuestionPage.vue';
import UserPlanList from "../components/pages/plan/UserPlanList.vue";
import UserSaveList from "../components/pages/save/UserSaveList.vue";
import SubSuccessNot from "../components/pages/plan/SubSuccessNot.vue";
import SaveSubSuccessNot from "../components/pages/save/SaveSubSuccessNot";
import LoanSubSuccessNot from "../components/pages/loan/LoanSubSuccessNot";
import BankTransferSuccess from "../components/pages/wallet/BankTransferSuccess.vue";
import BankWithdrawSuccess from "../components/pages/withdraw/BankWithdrawSuccess.vue";
import SendSellCoinSuccess from "../components/pages/wallet/SendSellCoinSuccess.vue";
import Withdraw from "../components/pages/withdraw/Withdraw.vue";
import AccountPage from '../components/pages/settings/AccountPage.vue';
import SettingsPage from '../components/pages/settings/SettingsPage.vue';
import BankAccount from '../components/pages/settings/BankAccount.vue';
import Dashboard from '../components/pages/dashboard/Dashboard.vue';
import BankTransfer from '../components/pages/wallet/BankTransfer.vue';
import BankTransferUSD from '../components/pages/wallet/BankTransferUSD.vue';
import History from '../components/pages/coin/History.vue';

import store from '@/store';
import pipeline from './middleware/pipeline.middleware';
import guest from './middleware/guest.middleware';
import auth from './middleware/auth.middleware';
import credible from './middleware/credible.middleware';
import verified from './middleware/verified.middleware';
// import question from './middleware/question.middleware';
// import loan from './middleware/loan.middleware';

const routes = [{
        path: '/',
        name: 'first-page',
        component: FirstPage,
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/splash',
        name: 'splash',
        component: SecondPage,
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterPage,
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/otp-page',
        name: 'otp-page',
        component: OtpPage,
        meta: {
            middleware: [
                // guest
            ]
        }
    },
    {
        path: '/otp-confirm-page',
        name: 'otp-confirm-page',
        component: OtpConfirmPage,
        meta: {
            middleware: [
                // guest
            ]
        }
    },
    {
        path: '/verified',
        name: 'verified',
        component: AccountVerified,
        meta: {
            middleware: [
                // guest
            ]
        }
    },
    {
        path: '/success',
        name: 'success',
        component: SuccessNotification,
        meta: {
            middleware: [
                // guest
            ]
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfilePage,
        meta: {
            middleware: [
                auth
            ]
        }
    },
    {
        path: '/verify',
        name: 'verify',
        component: VerificationPage,
        meta: {
            middleware: [
                auth,
                credible
            ]
        }
    },
    {
        path: '/select-app',
        name: 'select-app',
        component: SelectApp,
        meta: {
            middleware: [
                auth,
                verified
            ]
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            middleware: [
                auth,
                credible,
                verified,
                // question
            ]
        }
    },
    {
        path: '/questions',
        name: 'questions',
        component: QuestionPage,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/security-questions',
        name: 'security-questions',
        component: SecurityQuestionPage,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/loan-questions',
        name: 'loan-questions',
        component: LoanQuestionPage,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsPage,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/bank-account',
        name: 'bank-account',
        component: BankAccount,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/plans',
        name: 'plans',
        component: PlanList,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/my-plans',
        name: 'my-plans',
        component: UserPlanList,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/saves',
        name: 'saves',
        component: SaveList,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/loans',
        name: 'loans',
        component: LoanList,
        meta: {
            middleware: [
                auth,
                credible,
                verified,
                // loan
            ]
        }
    },
    {
        path: '/my-savings',
        name: 'my-savings',
        component: UserSaveList,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/my-loans',
        name: 'my-loans',
        component: UserLoanList,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/subscribe-success',
        name: 'subscribe-success',
        component: SubSuccessNot,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/save-subscribe-success',
        name: 'save-subscribe-success',
        component: SaveSubSuccessNot,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/loan-subscribe-success',
        name: 'loan-subscribe-success',
        component: LoanSubSuccessNot,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/bank-transfer-success',
        name: 'bank-transfer-success',
        component: BankTransferSuccess,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/send-sell-success',
        name: 'send-sell-success',
        component: SendSellCoinSuccess,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/withdraw',
        name: 'withdraw',
        component: Withdraw,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/bank-withdraw-success',
        name: 'bank-withdraw-success',
        component: BankWithdrawSuccess,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/bank-transfer',
        name: 'bank-transfer',
        component: BankTransfer,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/bank-transfer-usd',
        name: 'bank-transfer-usd',
        component: BankTransferUSD,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/transaction',
        name: 'transaction',
        component: Transaction,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/referral',
        name: 'referral',
        component: ReferralTransaction,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/services',
        name: 'services',
        component: VasServices,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/vas/:type',
        name: 'vas',
        component: Vas,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/trade/:coin',
        name: 'trade',
        component: History,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/account',
        name: 'account',
        component: AccountPage,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/cards',
        name: 'cards',
        component: CardPage,
        meta: {
            middleware: [
                auth,
                credible,
                verified
            ]
        }
    },
    {
        path: '/code/:referrer',
        name: 'referrer',
        component: FirstPage,
        meta: {
            middleware: [
                guest
            ]
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {

    const middleware = to.meta.middleware

    if (!middleware || middleware.length === 0) {
        return next()
    }

    const context = {
        to,
        from,
        next,
        store,
        _next: next
    };

    return middleware[0]({
        ...context,
        next: pipeline(context, middleware, 1)
    })
})

export default router