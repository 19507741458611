import {
  GetMyCards
} from "@/services/card";

export const getCards = (context, _query) => {
  context.commit("updateLoader", { fetchCards: true });
  return new Promise((resolve, reject) => {
    GetMyCards(_query)
    .then(res => {
      context.commit("loadCards", res.data.data);
      resolve(res.data.data)
    })
    .catch(err => {
      console.log(err);
      reject(err)
    })
    .finally(() => {
      // stop loaders
      context.commit("updateLoader", { fetchCards: false });
    });
  });
};


const CardAction = {
  getCards
};

export default CardAction;
