<template>
  <centralized-template>
    <div class="login-back-button">
      <a href="element-hero-blocks.html">
        <svg class="bi bi-arrow-left-short" width="32" height="32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
        </svg>
      </a>
    </div>
    <div class="login-wrapper d-flex align-items-center justify-content-center">
      <div class="custom-container">
        <div class="text-center"><img class="mx-auto mb-4 d-block" src="../../../assets/images/auth/36.png" alt="">
          <h3>Phone Verification</h3>
          <p>We will send you an OTP on this phone number.</p>
        </div>
        <!-- OTP Send Form -->
        <div class="otp-form mt-4">
          <form action="page-otp-confirm.html">
            <div class="input-group mb-3">
              <select class="input-group-text form-select form-control-clicked" name="dialingcode" aria-label="Default select example">
                <option value="+234" selected="">+234</option>
                <option value="+233">+233</option>
                <option value="+235">+235</option>
              </select>
              <input class="form-control" inputmode="numeric" pattern="[-+]?[0-9]*[.,]?[0-9]+" type="text" placeholder="Enter phone number">
            </div>
            <button class="btn btn-success w-100" type="submit" @click="confirm" >Send Now</button>
          </form>
        </div>
        <!-- Term & Privacy Info -->
        <div class="login-meta-data px-4 text-center">
          <p class="mt-3 mb-0">By providing my phone number, I hereby agree to the<a class="mx-1 stretched-link text-success" href="#">Term of Services</a>and <a class="mx-1 stretched-link text-success" href="#">Privacy Policy.</a></p>
        </div>
      </div>
    </div>
  </centralized-template>
</template>

<script>
// @ is an alias to /src
import CentralizedTemplate from '@/components/templates/CentralizedTemplate.vue'

export default {
  name: 'OtpPage',
  components: {
    CentralizedTemplate
  },
  methods: {
    confirm() {
      this.$router.push('/otp-confirm-page')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: rgba(var(--bs-success-rgb));
}
</style>
